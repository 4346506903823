import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'
import { NumberFormater } from "@/_helpers/funciones";
import { minValue, maxValue } from "vuelidate/lib/validators";

export default (
    maquinaryIsRequired, 
    Form, 
    Serialization, 
    SerialitationVolumen = 0, 
    SerialitationWeigth = 0,
    PackagingItem,
    ContainerItem,
    PackagingJsonList,
    isEditPackaging = false
) => {

    //Totals per Item
    let totalQuantity = PackagingJsonList?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0) ?? 0;
    let totalWeight = NumberFormater.formatNumber(PackagingJsonList?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2) ?? 0;
    //let TotalVolume = NumberFormater.formatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2);

    //Max in Quantity
    let maxTotalPackagingQuantity = ((NumberFormater.setNum(totalQuantity ?? 0) - Form?.Packaging?.maxPackagingQuantity)<0) ? ((NumberFormater.setNum(totalQuantity ?? 0) - Form?.Packaging?.maxPackagingQuantity)*-1) : (NumberFormater.setNum(totalQuantity ?? 0) - Form?.Packaging?.maxPackagingQuantity);
    let maxPackagingQuantity = !isEditPackaging ? maxTotalPackagingQuantity : maxTotalPackagingQuantity + NumberFormater.setNum(Form?.Packaging?.PackagingItem.Quantity);
    
    //ESTE VALOR HA SIDO COMENTADO PARA DEJAR LIBRE EL VOLUMEN Y HASTA CONOCER CORRECTAMENTE SI SE VALIDARA EL VOLUMEN CON LIMITACIONES POR BL O NO 
    let maxVolumen = false;// (Form?.Packaging?.maxPackagingVolumen && Form.Packaging.maxPackagingVolumen > 0) ? Form.Packaging.maxPackagingVolumen : false;
    let Length =  0;
    let Width = 0;
    let Height =  0;
    
    //Max in Weight
    let maxTotalPackagingWeight = ((NumberFormater.setNum(totalWeight ?? 0)  - Form?.Packaging?.maxPackagingWeight)<0) ? ((NumberFormater.setNum(totalWeight ?? 0) - Form?.Packaging?.maxPackagingWeight)*-1) : (NumberFormater.setNum(totalWeight ?? 0) - Form?.Packaging?.maxPackagingQuantity);
    let maxWeight = (Form?.Packaging?.maxPackagingWeight && Form.Packaging.maxPackagingWeight > 0) ? maxTotalPackagingWeight : false;

    //Max per cant in serialitation
    let maxPackaginVolumen = (PackagingItem?.maxPackagingVolumen && PackagingItem?.maxPackagingVolumen > 0) ? PackagingItem?.maxPackagingVolumen : (PackagingItem?.maxPackagingVolumen == 0) ? ContainerItem.Volumen :  99999999999;
    let maxPackaginWeight = (PackagingItem?.maxPackagingWeight && PackagingItem?.maxPackagingWeight > 0) ? PackagingItem.maxPackagingWeight : (PackagingItem?.maxPackagingWeight > 0) ? ContainerItem.Weight : 99999999999;

    //Max per cant of packagin in serialitation
    let PackagingVolumen = PackagingItem?.Volumen ?? 0;
    let PackagingWeigth = PackagingItem?.Weigth ?? 0;
    let IsNewSerial = Serialization.IsNewSerial ?? false;
    let serialitation = IsNewSerial ?  true : {required};

    //Total of Value of Serialitation
    let totalSerialWeight = PackagingItem?.SerialJson ? NumberFormater.setNum(NumberFormater.formatNumber((PackagingItem?.SerialJson?.filter(item => item.Status !== 0) ?? []).reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2)) : 0;
    let TotalSerialVolume = PackagingItem?.SerialJson ? NumberFormater.setNum(NumberFormater.formatNumber((PackagingItem?.SerialJson?.filter(item => item.Status !== 0) ?? []).reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2)) : 0;

    return {
        Form:{
            //Form Basic
            BasicData: {
                Observation: { onlyAlphanumeric5 },
                MachineId: maquinaryIsRequired ? {required} : {},
                TransactionDate: {required},
                DocumentComment: {},
                TpEirId: {},
                TpEirTransacId: {},
            },

            //Form For Packaging
            Packaging: {
                YardAreaId: { },
                PackagingId: { required },
                BlCargoMasterDetailId: { },
                VisitBillOfLadingPackagingId: { },
                maxPackagingQuantity: { },
                maxPackagingWeight: { },
                maxPackagingVolumen: { },
                VisitBillOfLadingId: { },
                VolumeCheck: { },
                Quantity: { 
                    minValue: minValue((PackagingItem?.SerialJson?.filter(item => item.Status !== 0) ?? []).length ?? 0),
                    maxValue: maxValue(maxPackagingQuantity),
                    NotNegative(Value) { 
                        return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true 
                    },
                    required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false }, 
                },
                Volumen: {
                    //IncorrectVolumenValue(Value) { return (NumberFormater.setNum(SerialitationVolumen) > 0 && NumberFormater.setNum(SerialitationVolumen) != Value) ? false : true },
                    minValue: minValue(TotalSerialVolume),
                    maxValue: maxVolumen ? maxValue(maxVolumen) : true,
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Length > 0 || Width > 0 || Height > 0) && Value == 0 ? false : true }, 
                },
                Length: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Width > 0 || Height > 0) && Value == 0 ? false : true }, 
                },
                Width: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Length > 0 || Height > 0) && Value == 0 ? false : true }, 
                },
                Height: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //required(Value) { return (Length > 0 || Width > 0) && Value == 0 ? false : true }, 
                },
                Weigth: { 
                    minValue: minValue(totalSerialWeight),
                    maxValue: maxWeight ? maxValue(maxWeight) : true,
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    //IncorrectVolumenWeightValue(Value) { return (NumberFormater.setNum(SerialitationWeigth) > 0 && NumberFormater.setNum(SerialitationWeigth) != Value) ? false : true },
                },
                //Seals 
                Seal: {
                    Seal1: {
                        Seal: { }
                    },
                    Seal2: {
                        Seal: { },
                    },
                    Seal3: {
                        Seal: { }
                    },
                    Seal4: {
                        Seal: { },
                    },
                },

                Serialization: {
                    Id: {},
                    Serial: { required },
                    PackagingId: {},
                    Serialized: serialitation,
                    DescriptionOfGoods: {},
                    VolumeCheck: {},
                    IsNewSerial: {},
                    Weight: {
                        maxValue: maxWeight ? maxValue(maxWeight) : true,
                        maxLengthDeclared(Value) { return ((( NumberFormater.setNum(SerialitationWeigth) + NumberFormater.setNum(Value))) > maxPackaginWeight)? false : true },
                        //maxLengthDeclared(Value) { return ((( NumberFormater.setNum(SerialitationWeigth) + NumberFormater.setNum(Value)) > maxPackaginWeight) || ( NumberFormater.setNum(SerialitationWeigth) + NumberFormater.setNum(Value)) > PackagingWeigth)? false : true },
                        NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                        required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false }, 
                    },
                    Volumen: {
                        NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                        //maxLengthDeclared(Value) { return (( NumberFormater.setNum(SerialitationVolumen) + NumberFormater.setNum(Value)) > maxPackaginVolumen) ? false : true },
                        //maxLengthDeclared(Value) { return (( NumberFormater.setNum(SerialitationVolumen) + NumberFormater.setNum(Value)) > maxPackaginVolumen) || (( NumberFormater.setNum(SerialitationVolumen) + NumberFormater.setNum(Value)) > PackagingVolumen) ? false : true },
                    },
                    Length: {NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true }},
                    Width: {NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true }},
                    Height: {NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true }}
                },
            },

            //Form Logistic
            Logistic: {
                CarrierId: { },
                numberIdLetter: { },
                SearchDriver: { },
                DriverId: { },
                SearchPlate: { },
                VehicleId: { },
            },

            //Form Details
            DetailJson: {required(value){ return value.length > 0 }}
        },
        
    }
}