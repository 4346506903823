<template>
  <div class="mb-3">
    <div v-show="showIndex==0">
      <CRow class="mb-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${$t('label.PendingReceptions')} - ${$t('label.internalTransfer')}`}} </h6>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedListContainer"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  square
                  size="sm"
                  color="watch"
                  class="d-flex align-items-center"
                  @click="toggleAdd(item)"
                  v-c-tooltip="{
                    content: $t('label.reception'),
                    placement: 'top-start'
                  }"
                >
                  <CIcon name='cil-list' />
                </CButton>
              </td>
            </template>
            <template #Weight="{ item }">
              <td class="text-center">
                {{ NumberFormat(item.Weight, 2) }}
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
    </div>
    <div v-show="showIndex==1">
      <PendingRegister
				:Active="showIndex==1"
        :FgInternalTransfer="true"
				:ContainerItem="ContainerInfo"
				@Close="showIndex = 0, ContainerInfo={}"
				@Update-list="getYardCargoPendingInternalTransferList"
			/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import PendingRegister from '@/pages/yard-management/movements-list/reception/pending-register';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    Items: [],
    PendingList: [],
    ContainerInfo: {},
    showIndex: 0,
  };
}

//methods
async function getYardCargoPendingInternalTransferList(loading=false) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargoPendingInternalTransfer-list', { YardId: this.YardId })
    .then((response) => {
      this.PendingList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = loading;
      if (this.FgPositioning) {
        this.$store.state.yardManagement.ContainerTabIndex = 2;
        this.$store.state.yardManagement.dropContainerMovement = 3;
        this.$store.state.yardManagement.yardCollapse = 2;
      }
    });
}

function NumberFormat(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function toggleAdd(item) {
  this.ContainerInfo = item;
  this.showIndex = 1;
}

//computed
function computedListContainer() {
  return this.PendingList.map((item) => {
    return {
      ...item,
      Nro: Number.parseInt(item.Nro),
      ContainerCode: item.ContainerCode ?? '',
      Bl: item?.BlJson?.map(e => e.BlNro)?.join(', ') ?? '',
      VesselVoyage: item.VesselName ? `${item.VesselName} - ${item.VoyageArrival}` : '',
      AtaEta: `${item.Arrival ? DateFormater.formatDateTimeWithSlash(item.Arrival) : ''}`,
      TpCargoStatusName: item.TpCargoStatusName ?? '',
      TpCargoDetailCode: item.TpCargoDetailCode ?? '',
      ShippingLineName: item.ShippingLineName ?? '',
      TpCargoConditionName: item.TpCargoConditionName ?? '',
      TpTransacEirAcronym: item.TpTransacEirAcronym ?? '',
      Status: item.FgActCargoMaster ? 1 : 0,
      TransaLogin: item.TransaLogin ?? '',
      ComputedReception: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : 'N/A',
      ComputedDispatch: item.DispatchDate ? DateFormater.formatDateTimeWithSlash(item.DispatchDate) : 'N/A',
    };
  })
}

function fields(){ 
  return [
    { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
    { key: 'ContainerCode', label: this.$t('label.container'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
    { key: 'YardName', label: this.$t('label.YardWarehouseOrigin'),  _classes: 'text-uppercase text-center', _style: 'min-width: 220px;'},
    { key: 'Bl', label: this.$t('label.bl'),  _style:'min-width:180px;', _classes:" text-center"},
    { key: 'VesselVoyage', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`,  _classes: 'text-uppercase text-center', _style: 'min-width: 230px;'},
    { key: 'AtaEta', label: 'ATA / ETA',  _classes: 'text-uppercase text-center', _style: 'min-width: 130px; '},
    { key: 'TpCargoDetailCode', label: `${this.$t('label.type')}`, _style:'min-width: 140px;', _classes: 'text-uppercase text-center'},
    { key: 'TpCargoStatusName', label: `${this.$t('label.status')}`, _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
    { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'),  _classes: 'text-uppercase text-center', _style: 'min-width: 190px;'},
    { key: 'TpCargoConditionCode', label: this.$t('label.condition'),  _classes: 'text-uppercase text-center', _style: 'min-width: 190px;'},
    { key: 'ShippingLineName', label: this.$t('label.newShippingLine'),  _classes: 'text-uppercase text-center', _style: 'min-width: 170px;'},
    { key: 'ComputedReception', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 190px;'},
    { key: 'ComputedDispatch', label: this.$t('label.dispatchDate'), _classes: 'text-center', _style: 'min-width: 190px;'},
    { key: 'DaysInYard', label: this.$t('label.DaysInYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:230px;',},
  ];
}

export default{
  name: 'internal-transfer',
  data,
  mixins: [General],
  components: {
    PendingRegister,
  },
  methods: {
    toggleAdd,
    getYardCargoPendingInternalTransferList,
    NumberFormat
  },
  computed: {
    fields,
    computedListContainer,
    ...mapState({
      YardId: state => state.yardManagement.yardData.YardId,
      dropItemMovementList: state => state.yardManagement.dropItemMovementList,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
  watch:{
    dropItemMovementList: function (val) {
      if (val==3) {
        this.showIndex = 0;
        this.PendingList = [];
        this.getYardCargoPendingInternalTransferList(false);
      }
    }
  }
}
</script>